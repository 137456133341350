<template>
  <div id="app">
    <mainPage/>
  </div>
</template>

<script>
import mainPage from './components/mainPage.vue'

export default {
  name: 'app',
  components: {
    mainPage
  }
}
</script>

<style>
	@import './assets/css/style.css';
</style>
